<template>
  <div class="profile box-container" style="margin: 0 auto; width: 900px">
    <h1>{{ $t("settings.personal_infos") }}</h1>
    <div class="flex">
      <div class="input-box">
        <h2>{{ $t("overall.first_name") }}</h2>
        <input type="text" v-model="profile.first_name" />
      </div>
      <div class="input-box">
        <h2>{{ $t("overall.last_name") }}</h2>
        <input type="text" v-model="profile.last_name" />
      </div>
    </div>
    <div class="flex">
      <div class="input-box">
        <h2>{{ $t("overall.username") }}</h2>
        <input type="text" v-model="profile.username" />
      </div>
      <div class="input-box">
        <h2>{{ $t("overall.birthday") }}</h2>
        <Datepicker
          v-model="profile.birthday"
          :fromDate="todayDate.getFullYear() - 18"
          :toDate="todayDate.getFullYear() - 100"
        />
      </div>
      <div class="input-box">
        <h2>{{ $t("overall.timezone") }}</h2>
        <Select :options="timezones" v-model="profile.timezone" />
      </div>
    </div>
    <div>
      <h1>{{ $t("settings.interface_lang") }}</h1>
      <Select :options="langOptions" v-model="profile.interface_lang" />
    </div>
    <div>
      <h1>{{ $t("settings.security") }}</h1>
      <h2>{{ $t("overall.password") }}</h2>
      <input type="text" :placeholder="$t('settings.current_password')" v-model="currentPassword" />
      <input type="text" :placeholder="$t('settings.new_password')" v-model="newPassword" />
      <input type="text" :placeholder="$t('settings.repeat_password')" v-model="repeatPassword" />
      <span class="error">{{ errorPassword }}</span>
      <button @click="updatePassword" class="cta" :class="{ disabled: !isPasswordOk }">
        {{ $t("settings.update_password") }}
      </button>
    </div>
    <h1>{{ $t("settings.business_infos") }}</h1>
    <div class="input-box">
      <h2>{{ $t("overall.rank") }}</h2>
      <strong>{{ $t("overall." + profile.rank) }}</strong>
    </div>
    <div class="input-box">
      <h2>{{ $t("overall.email") }}</h2>
      <strong>{{ profile.email }}</strong>
    </div>
    <div class="input-box">
      <h2>{{ $t("overall.phone") }}</h2>
      <input type="text" v-model="profile.phone" />
    </div>

    <div class="actions-bar">
      <template v-if="!loading">
        <button class="cancel">{{ $t("overall.cancel") }}</button>
        <button class="cta save fixed-button" @click="save">
          <span class="txt">{{ $t("overall.save") }}</span>
        </button>
      </template>
      <span class="loader" v-else></span>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

import Editor from "@/components/Editor";
import modalBus from "@/bus/modal-bus";
import notifBus from "@/bus/notification-bus";

export default Vue.extend({
  name: "EditArticle",
  data() {
    return {
      timezones: [
        {
          name: "Europe/Madrid",
          value: "Europe/Madrid",
        },
        {
          name: "Europe/Paris",
          value: "Europe/Paris",
        },
      ],
      currentPassword: null,
      newPassword: "azertyu",
      repeatPassword: "azertyu",
      errorPassword: null,
      loading: null,
      langOptions: [
        { value: "es", name: "spanish" },
        { value: "en", name: "english" },
        { value: "fr", name: "french" },
        { value: "de", name: "german" },
        { value: "it", name: "italian" },
        { value: "pt", name: "portuguese" },
      ],
      profile: {
        interface_lang: null,
        first_name: null,
        last_name: null,
        username: null,
        email: null,
        password: null,
        birthday: null,
        profile_picture: null,
        timezone: null,
      },
    };
  },
  computed: {},
  methods: {
    isPasswordOk() {
      const minLength = 7;
      if (this.newPassword) {
        if (this.newPassword.length < minLength) {
          this.errorPassword = this.$t("settings.password_min_length");
          return false;
        } else {
          this.errorPassword = null;
        }
        return !!(this.currentPassword && this.newPassword && this.newPassword === this.repeatPassword);
      }
      this.errorPassword = null;
      return false;
    },
    updatePassword() {
      this.$axios
        .post("/api/users/update-password", {
          currentPassword: this.currentPassword,
          newPassword: this.newPassword,
        })
        .then((res) => {
          console.log(res.data);
          notifBus.$emit("show", {
            status: "success",
            message: this.$t("settings.password_updated"),
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    save() {
      this.$axios
        .post("/api/users/update-profile", {
          profile: this.profile,
        })
        .then((res) => {
          console.log(resp);
          notifBus.$emit("show", {
            status: "success",
            message: this.$t("settings.profile_updated"),
          });
        });
    },
  },
  created() {
    this.$axios.get("/api/users/profile").then((res) => {
      this.profile = res.data;
      console.log(this.profile);
    });
  },
});
</script>
